import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

//hooks
import { useLoggedInStore } from '@/utils/zustand/providers/logged-in-provider';
import useUser from '@/utils/hooks/swr/useUser';

export function IsUserLoggedIn() {
    const { user } = useUser();

    const { isLoggedIn, updateIsLoggedIn } = useLoggedInStore((state) => state);

    useEffect(() => {
        if (isLoggedIn || !user) {
            return;
        }
        // trigger mixpanel login event
        mixpanel?.track('mixpanel_login_completed');
        //maj store
        updateIsLoggedIn(true);
    }, [isLoggedIn, updateIsLoggedIn, user]);

    useEffect(() => {
        if (!user && isLoggedIn) {
            // reset store when no user
            updateIsLoggedIn(false);
        }
    }, [isLoggedIn, updateIsLoggedIn, user]);

    return null;
}
