import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';

import useRouterQuery from '@/hooks/utils/useRouterQuery';

export function usePartnerStackCookie() {
    const router = useRouter();
    const { clearUrlQueriesWithoutReload } = useRouterQuery();
    const { idbn, fpr, prms } = router.query;

    useEffect(() => {
        if (!idbn && !fpr && !prms) {
            return;
        }
        if (idbn) {
            Cookies.set('ico_idbn', idbn, {
                domain: '.iconosquare.com',
                expires: 30
            });
        }

        if (fpr) {
            Cookies.set('fpr', fpr, {
                domain: '.iconosquare.com',
                expires: 30
            });
        }

        if (prms) {
            Cookies.set('prms', prms, {
                domain: '.iconosquare.com',
                expires: 1
            });
        }

        //clear query params
        clearUrlQueriesWithoutReload();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idbn, fpr, prms]);
}
