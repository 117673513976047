import { createStore } from 'zustand/vanilla';
import { immer } from 'zustand/middleware/immer';

// Types
import type { SocialProfileType } from '@/types/socialProfileType';
import type { SpGroupType } from '@/utils/hooks/swr/group/useSPGroups';
import {
    deleteActiveGroupIdLocalStorage,
    deleteActiveMultiProfileIdsLocalStorage,
    deleteActiveProfileIdLocalStorage,
    setActiveGroupIdLocalStorage,
    setActiveMultiProfileIdsLocalStorage,
    setActiveProfileIdLocalStorage
} from '@/utils/localStorage/profilesLocalStorage';

export type ProfilesStoreStateType = {
    activeSocialProfileId: SocialProfileType['id'];
    activeGroupId: SpGroupType['id'];
    activeMultiSelectionProfiles: {
        socialProfileIds: SocialProfileType['id'][];
    };
};

export type ProfilesStoreActionsType = {
    manageProfiles: (
        profiles: SocialProfileType[],
        activeProfileId?: SocialProfileType['id']
    ) => void;
    manageGroups: (group: SpGroupType) => void;
    updateActiveSocialProfileId: (profileId: SocialProfileType['id']) => void;
    updateActiveGroupId: (groupId: SpGroupType['id']) => void;
    updateActiveMultiProfileIds: (profileIds: SpGroupType['id'][]) => void;
    resetActiveGroup: () => void;
    resetActiveSocialProfile: () => void;
    resetActiveMultiSelectionProfiles: () => void;
};

export type ProfilesStoreType = ProfilesStoreStateType & ProfilesStoreActionsType;

export const defaultInitState: ProfilesStoreStateType = {
    activeSocialProfileId: null,
    activeGroupId: null,
    activeMultiSelectionProfiles: null
};

export const createProfilesStore = (initState: ProfilesStoreStateType = defaultInitState) => {
    return createStore<ProfilesStoreType>()(
        immer((set, get) => ({
            ...initState,
            manageProfiles: (profiles, profileIdToBeActivated) => {
                // Clear active social group
                get().resetActiveGroup();

                const activeProfileId = profileIdToBeActivated ?? profiles[0].id;

                /**
                 * If only one profile is selected, set the activeSocialProfile entry
                 */
                if (profiles.length === 1) {
                    // Clear active multi selection profiles
                    get().resetActiveMultiSelectionProfiles();

                    // Set active social profile
                    get().updateActiveSocialProfileId(activeProfileId);
                    return;
                }

                /**
                 * If multi profiles are selected, set the activeMultiSelectionProfiles entry
                 * and clear the activeSocialProfile entry
                 */
                if (
                    !get().activeSocialProfileId ||
                    profileIdToBeActivated ||
                    (get().activeSocialProfileId &&
                        !profiles.find((profile) => profile.id === get().activeSocialProfileId))
                ) {
                    get().updateActiveSocialProfileId(activeProfileId);
                }

                const socialProfileIds = profiles.map((profile) => profile.id);
                get().updateActiveMultiProfileIds(socialProfileIds);
            },
            manageGroups: (group) => {
                // Clear active social profile
                get().resetActiveSocialProfile();

                // Clear active multi selection profiles
                get().resetActiveMultiSelectionProfiles();

                // Set active social group
                get().updateActiveGroupId(group.id);
            },
            updateActiveSocialProfileId: (profileId) => {
                set((state) => {
                    state.activeSocialProfileId = profileId;
                    setActiveProfileIdLocalStorage(profileId);
                });
            },
            updateActiveGroupId: (groupId) => {
                set((state) => {
                    state.activeGroupId = groupId;
                    setActiveGroupIdLocalStorage(groupId);
                });
            },
            updateActiveMultiProfileIds: (profileIds) => {
                // Clear active group
                get().resetActiveGroup();

                set((state) => {
                    state.activeMultiSelectionProfiles = {
                        socialProfileIds: profileIds
                    };
                    setActiveMultiProfileIdsLocalStorage(profileIds);
                });
            },
            resetActiveSocialProfile: () => {
                set((state) => {
                    state.activeSocialProfileId = null;
                    deleteActiveProfileIdLocalStorage();
                });
            },
            resetActiveGroup: () => {
                set((state) => {
                    state.activeGroupId = null;
                    deleteActiveGroupIdLocalStorage();
                });
            },
            resetActiveMultiSelectionProfiles: () => {
                set((state) => {
                    state.activeMultiSelectionProfiles = null;
                    deleteActiveMultiProfileIdsLocalStorage();
                });
            }
        }))
    );
};
