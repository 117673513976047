import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { useRouter } from 'next/router';
import mixpanel from 'mixpanel-browser';

// Types
import type { Config } from 'mixpanel-browser';

// Hooks
import useUser from '@/utils/hooks/swr/useUser';

const RECORD_MAX_MS = 600000;
const RECORD_MIN_MS = 4000;
const MAX_PERCENT_RECORD_SESSIONS_PERCENT = 20;

export function MixPanelInitialize() {
    const [trackAnalytics, setTrackAnalytics] = useState(false);
    const [hubspotDelay, setHubspotDelay] = useState<number | null>(500);

    const defaultMixPanelConfig: Partial<Config> = {
        persistence: 'localStorage',
        record_mask_text_selector: '',
        record_max_ms: RECORD_MAX_MS,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        record_min_ms: RECORD_MIN_MS,
        ignore_dnt: true //bypass the do not track browser setting
    };

    useEffect(() => {
        if (!window?.mixpanel) {
            window.mixpanel = mixpanel;
        }

        return () => {
            if (window?.mixpanel) {
                delete window.mixpanel;
            }
        };
    }, []);

    useEffect(() => {
        // Active or deactivate session recording
        mixpanel.set_config({
            record_sessions_percent: trackAnalytics ? MAX_PERCENT_RECORD_SESSIONS_PERCENT : null
        });
    }, [trackAnalytics]);

    useInterval(() => {
        // wait for HubSpot to be fully loaded
        if (window?._hsp) {
            window._hsp.push([
                'addPrivacyConsentListener',
                function (bannerInfo) {
                    setTrackAnalytics(bannerInfo?.categories?.analytics);
                }
            ]);
            setHubspotDelay(null); // Stop the interval
        }
    }, hubspotDelay);

    mixpanel.init(
        process.env.NEXT_PUBLIC_MIXPANEL_API_KEY,
        process.env.NEXT_PUBLIC_MIXPANEL_API_HOST
            ? { ...defaultMixPanelConfig, api_host: process.env.NEXT_PUBLIC_MIXPANEL_API_HOST }
            : defaultMixPanelConfig
    );

    return <MixPanelInitializeUser />;
}

function MixPanelInitializeUser() {
    const router = useRouter();
    const { user } = useUser();

    useEffect(() => {
        mixpanel.identify(String(user.admin_id));

        /**
         * set user properties
         */
        mixpanel.people.set({
            $email: user.email,
            $user_id: user.id,
            $first_name: user?.first_name ?? '',
            $last_name: user?.last_name ?? ''
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function handleRouteChange(page: string, params?: { shallow: boolean }) {
            if (params?.shallow) {
                return;
            }
            mixpanel.track_pageview({ page });
        }

        handleRouteChange(router.pathname);

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}
